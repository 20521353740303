.HonoursBoard .year-col {
  width: 15%;
}
.HonoursBoard .year-single-col {
  width: 15%;
}
.HonoursBoard .name-col {
  width: 42.5%;
}
.HonoursBoard .name-single-col {
  width: 85%;
}

@media (min-width: 1500px) {
  .HonoursBoard .year-single-col {
    width: 7.5%;
  }
  .HonoursBoard .name-single-col {
    width: 92.5%;
  }
}
