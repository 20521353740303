.TeamScoresForRound .team-col {
  width: 45%;
  text-align: center;
}
.TeamScoresForRound .v-col {
  width: 10%;
  text-align: center;
}
.TeamScoresForRound .total {
  font-weight: bold;
}
.TeamScoresForRound {
  margin-top: 30px;
}
.TeamScoresForRound.table-striped > tbody > tr.buffer-row {
  height: 28px;
  background-color: #ddd;
}

.TeamScoresForRound .winner.hookers {
  color: #fff;
  background-color: #7eb7d8;
}

.TeamScoresForRound .winner.swingers {
  color: #fff;
  background-color: #483fc4;
}
