.BadgesList .badge-item {
  margin-top: 15px;
}

.BadgesList td .badge-item,
.BadgesList td a {
  margin: 10px 5px;
  display: block;
}

.BadgesList h4 {
  display: inline-block;
}

.BadgesList .badge-item .fas {
  margin-right: 10px;
  font-size: 20px;
}

.BadgesList .badge-item.un-earned-badge .fas {
  color: grey !important;
}

.BadgesList .slim-list {
  display: none;
}

.BadgesList .a-badge {
  margin: 8px 0;
}

.BadgesList .jacket-icon {
  margin-right: 8px;
}

@media (max-width: 700px) {
  .BadgesList table {
    display: none;
  }
  .BadgesList .slim-list {
    display: block;
  }
}

.BadgesList .badge {
  margin-left: 5px;
  font-size: 10px;
  background-color: #d8bf3a;
  border-radius: 0;
}

.BadgesList .badge .fas {
  font-size: 10px;
  margin-right: 1px;
}

.BadgesList .no-badges {
  margin-top: 30px;
}
