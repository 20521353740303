.Honours .section-subheading {
  margin-bottom: 10px;
}

.Honours .jacket-icon {
  height: 25px;
  padding-right: 15px;
  position: relative;
  bottom: 3px;
}
