.Round .capitalize {
  text-transform: capitalize;
}

.Round .add-group {
  margin: 25px 0;
}

.Round .golfer-selected {
  margin-top: 10px;
}

.Round .invalid {
  margin-top: 10px;
}

.Round .confirm-group {
  margin-top: 40px !important;
}

.Round .comp-types-help {
  margin-top: 15px;
}

.Round .comp-types-list {
  padding-left: 25px;
  margin-top: 5px;
}

.Round .nav.nav-pills {
  width: 100%;
  white-space: nowrap;
}

.Round .nav.nav-pills li {
  text-align: center;
  width: 50%;
  display: inline-block;
  white-space: normal;
  float: none;
  margin-left: 0;
  margin-right: 0;
}

.Round .nav.nav-pills li a {
  cursor: not-allowed;
  border-radius: 0;
}

.Round .nav.nav-pills li.clickable-nav a {
  cursor: pointer;
}

.Round .btn-group {
  margin-top: 40px;
  width: 100%;
}

.Round .btn-group .btn {
  width: 50%;
}

.Round .calculate-handicaps-btn {
  margin-top: 10px !important;
}

.Round .alert h3 {
  margin-top: 5px;
}

.Round .handicaps-updated {
  margin-bottom: 20px;
}

.Round .remove-scorecard {
  font-size: 12px;
  cursor: pointer;
  color: #bf69a5;
  margin-left: 5px;
}

.Round .remove-scorecard:hover {
  color: #ae5995;
}
