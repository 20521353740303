.HandicapRules .rules-content div {
  margin: 10px 0;
}

.HandicapRules ol {
  margin-top: 20px;
  padding-inline-start: 20px;
}

.HandicapRules li {
  margin-top: 10px;
  padding-left: 10px;
}
