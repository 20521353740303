.ReigningChampions .comp-col {
  width: 50%;
}
.ReigningChampions .name-col {
  width: 50%;
}
.ReigningChampions .jacket-icon,
.ReigningChampions .champ {
  margin-right: 10px;
}

.ReigningChampions .champ.fas.fa-tshirt {
  color: #bf69a5;
}
