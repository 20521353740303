.Course .holes-container {
  padding: 0;
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
}

.Course .tee-list {
  margin: 20px 0;
}

.Course .white-tee {
  background-color: #ffffff;
}

.Course .yellow-tee {
  background-color: #e8e841;
}

.Course .red-tee {
  background-color: #f94659;
}

.Course .white-tee:hover,
.Course .white-tee:focus {
  background-color: #eee;
  outline: none;
}

.Course .yellow-tee:hover,
.Course .yellow-tee:focus {
  background-color: #d4cd41;
  outline: none;
}

.Course .red-tee:hover,
.Course .red-tee:focus {
  background-color: #e54253;
  outline: none;
}

.Course .tee-breadcrumb {
  text-transform: capitalize;
}

.Course .holes-table .form-group {
  margin: 0;
}

.holes-container form {
  max-width: none;
  margin: 0 auto 40px auto;
}

.page-content .Course .breadcrumb {
  margin-bottom: 10px;
  border-bottom: 4px solid #bf69a5;
}

.holes-table .error-message {
  display: none;
}

.holes-table .has-error .error-message {
  margin-top: 7px;
  display: block;
}

.holes-table td {
  width: 28.3%;
}

.holes-table td:first-child {
  width: 15%;
}
