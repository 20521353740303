.RyderCupHonoursBoard .year-col {
  width: 15%;
}
.RyderCupHonoursBoard .name-col {
  width: 42.5%;
}
.RyderCupHonoursBoard .name-single-col {
  width: 85%;
}

.RyderCupHonoursBoard .winner.hookers {
  color: #fff;
  background-color: #7eb7d8;
}

.RyderCupHonoursBoard .winner.swingers {
  color: #fff;
  background-color: #483fc4;
}

.RyderCupHonoursBoard .overall {
  font-weight: bold;
}
