.password-validation {
  width: 100%;
  border-color: #ccc;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 6px 6px;
  padding: 10px;
}

@media all and (min-width: 1024px) {
  .password-validation {
    position: absolute;
    left: -350px;
    top: 27px;
    border-width: 3px;
    border-radius: 6px;
    border-color: #bf69a5;
    background-color: #ffffff;
  }

  .password-validation:after,
  .password-validation:before {
    left: 100%;
    top: 24px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .password-validation:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #fff;
    border-width: 15px;
    margin-top: -15px;
  }
  .password-validation:before {
    border-color: rgba(6, 175, 142, 0);
    border-left-color: #bf69a5;
    border-width: 19px;
    margin-top: -19px;
  }
  .password-validation .password-rule td {
    color: #fff;
  }
}

.password-validation .glyphicon {
  margin-right: 10px;
  font-size: 25px;
}

.password-validation .password-validation-title {
  margin: 10px 0;
  font-weight: bold;
  color: #aaa;
}

.password-validation .password-rule td {
  color: #aaa;
  padding: 3px 0;
}

.password-validation .password-rule-text {
  position: relative;
  top: -2px;
}
